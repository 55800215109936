import React from 'react';
import { Link } from 'react-router-dom';
import tree from './tree.png';

const Tree = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#F06060',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '315px',
    height: '383.4px',
  };

  const captionStyle = {
    width: '315px', // Set the width to match the image width
    textAlign: 'center', // Align text to the center
    marginTop: '10px', // Adjust margin as needed
  };

  const textStyle = {
    textDecoration: 'underline',
    fontSize: '14px', // Adjust the font size as needed
    marginTop: '10px', // Add margin to create space between image and link
  };

  const textStyleBox = {
    fontSize: '12px', // Adjust the font size as needed
    marginTop: '10px', // Add margin to create space between image and link
  };
  return (
    <div className="App" style={appStyle}>
      <figure>
        <img src={tree} alt="I love you" style={imgStyle} />
        <figcaption style={captionStyle}>
          <em style={textStyleBox}>
          You can sleep now, you said. You can sleep now. You said that.</em>
            <br/>
            <br/>
            <em style={textStyleBox}>
            I had a dream where you said that. Thanks for saying that.
          </em>
        </figcaption>
      </figure>
      <h1 style={textStyle}>
        <Link to="/garden">put picture back under tree</Link>
      </h1>
    </div>
  );
};

export default Tree;
