// HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import outside from './outside.png';

const Outside = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#F3B562',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const columnsStyle = {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '600px',
    height: '600px',
  };

  const textStyle = {
    textDecoration: 'underline',
  };

  return (
    <div className="App" style={appStyle}>
      <div className="columns" style={columnsStyle}>
        <div className="left-column" style={{ flex: 1 }}>
          <figure>
          <img src={outside} alt="I love you" style={imgStyle} />
          <figcaption><em>Is that too much to expect? That I would name the stars
for you? That I would take you there? The splash
of my tongue melting you like a sugar cube? </em></figcaption>
          </figure>
          
        </div>
        <div className="right-column" style={{ flex: 1 }}>
        <h1 style={textStyle}>
            <Link to="/garden">sit in the garden</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/backyard">backyard</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/night">spend the night outside</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/">go back inside</Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Outside;
