// HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import box from './box.png';

const Box = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#8CBEB2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '315px',
    height: '383.4px',
  };

  const captionStyle = {
    width: '315px', // Set the width to match the image width
    textAlign: 'center', // Align text to the center
    marginTop: '10px', // Adjust margin as needed
  };

  const textStyle = {
    textDecoration: 'underline',
    fontSize: '14px', // Adjust the font size as needed
    marginTop: '10px', // Add margin to create space between image and link
  };

  const textStyleBox = {
    fontSize: '12px', // Adjust the font size as needed
    marginTop: '10px', // Add margin to create space between image and link
  };
  return (
    <div className="App" style={appStyle}>
      <figure>
        <img src={box} alt="I love you" style={imgStyle} />
        <figcaption style={captionStyle}>
          <em style={textStyleBox}>
            All night I stretched my arms across him, rivers of blood, the dark woods, singing
            with all my skin and bone</em>
            <br/>
            <br/>
            <em style={textStyleBox}>
            ''Please keep him safe.
            Let him lay his head on my chest and we will be
            like sailors, swimming in the sound of it, dashed
            to pieces.
          </em>
        </figcaption>
      </figure>
      <h1 style={textStyle}>
        <Link to="/backyard">put picture back in the box</Link>
      </h1>
    </div>
  );
};

export default Box;
