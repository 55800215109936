import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import night from './night.png';
import ImagePopup from './ImagePopup';
import up from './up.png';
import remember from './remember.png';

const Night = () => {
    const [popupOpen, setPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openPopup = (image) => {
    setSelectedImage(image);
    setPopupOpen(true);
  };

  const closePopup = () => setPopupOpen(false);


  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#F2EBBF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const columnsStyle = {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '600px',
    height: '600px',
  };

  const textStyle = {
    textDecoration: 'underline',
  };

  return (
    <div className="App" style={appStyle}>
      <div className="columns" style={columnsStyle}>
        <div className="left-column" style={{ flex: 1 }}>
          <figure>
          <img src={night} alt="I love you" style={imgStyle} />
          <figcaption><em> how we rolled up the carpet so we could dance, and the days were bright red, and every time we kissed there was another
apple
to slice into pieces. </em></figcaption>
          </figure>
          
        </div>
        <div className="right-column" style={{ flex: 1 }}>
        <h1 style={textStyle}>
            <Link to="#" onClick={() => openPopup(up)}>look up at the sky</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="#" onClick={() => openPopup(remember)}>remember</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/outside">back towards cabin</Link>
          </h1>
        </div>
      </div>
      <ImagePopup
        imageUrl={selectedImage}
        isOpen={popupOpen}
        onRequestClose={closePopup}
      />
    </div>
  );
};

export default Night;
