// HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import home from './home.png';

const HomePage = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#8CBEB2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const columnsStyle = {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '600px',
    height: '600px',
  };

  const textStyle = {
    textDecoration: 'underline',
  };

  return (
    <div className="App" style={appStyle}>
      <div className="columns" style={columnsStyle}>
        <div className="left-column" style={{ flex: 1 }}>
          <figure>
          <img src={home} alt="I love you" style={imgStyle} />
          <figcaption><em>My dragonfly,
my black-eyed fire, the knives in the kitchen are singing
for blood, but we are the crossroads, my little outlaw </em></figcaption>
          </figure>
          
        </div>
        <div className="right-column" style={{ flex: 1 }}>
          <h1 style={textStyle}>
            <Link to="/outside">explore</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/album">check under the bed</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/cabin">stay inside cabin</Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
