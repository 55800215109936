// love.js
import React from 'react';
import { Link } from 'react-router-dom';
import love from './love.jpeg';

const Love = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#F06060',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const columnsStyle = {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '600px',
    height: '600px',
  };

  const textStyle = {
    textDecoration: 'underline',
  };

  return (
    <div className="App" style={appStyle}>
      <div className="columns" style={columnsStyle}>
        <div className="left-column" style={{ flex: 1 }}>
          <figure>
          <img src={love} alt="I love you" style={imgStyle} />
            <figcaption><em>Tell me how all this, and love too, will ruin us.
These, our bodies, possessed by light.
Tell me we'll never get used to it.</em></figcaption>
          </figure>
          
        </div>
        <div className="right-column" style={{ flex: 1 }}>
          <h1 style={textStyle}>
            <Link to="/cabin">go back</Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Love;
