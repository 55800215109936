import React from 'react';
import { Link } from 'react-router-dom';
import album from './album.png';

const Album = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#ffde69',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '432px',
    height: '540px',
  };



  const textStyle = {
    textDecoration: 'underline',
    fontSize: '14px', // Adjust the font size as needed
    marginTop: '10px', // Add margin to create space between image and link
  };


  return (
    <div className="App" style={appStyle}>
      <figure>
        <img src={album} alt="I love you" style={imgStyle} />
      </figure>
      <h1 style={textStyle}>
        <Link to="/">put album back under bed</Link>
      </h1>
    </div>
  );
};

export default Album;
