// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import SecretMessage from './SecretMessage';
import Cabin from './cabin';
import Love from './love';
import Outside from './outside';
import Backyard from './backyard';
import Box from './box';
import Bathroom from './bathroom';
import Shower from './shower';
import Warm from './warm';
import Garden from './garden';
import Tree from './tree';
import Night from './night';
import Album from './album';
import './App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/message" element={<SecretMessage />} />
        <Route path="/cabin" element={<Cabin />} />
        <Route path="/safe" element={<Love />} />
        <Route path="/outside" element={<Outside />} />
        <Route path="/backyard" element={<Backyard />} />
        <Route path="/box" element={<Box />} />
        <Route path="/bathroom" element={<Bathroom />} />
        <Route path="/shower" element={<Shower />} />
        <Route path="/warm" element={<Warm />} />
        <Route path="/garden" element={<Garden />} />
        <Route path="/tree" element={<Tree />} />
        <Route path="/night" element={<Night />} />
        <Route path="/album" element={<Album />} />
      </Routes>
    </Router>
  );
};

export default App;
