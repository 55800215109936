import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './secretMessage.css'; // Import your CSS file


const SecretMessage = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX - 100, y: e.clientY - 100 });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="flashlight-page">
      <h2>love you for 10,000 years</h2>
      <p>You have come into my life and made me see a future. I never wanted to be in a relationship with anyone. Not until I met you. Getting better seemed like an impossibility or something shameful until you. You. Ma-na-hil. Blue. Here we are, on your birthday, a little over two weeks from a year of us, and stronger than ever. The last two months we haven’t been able to meet much, or spend time together much, and you have been working so hard, and I miss you very much most nights, but today. On your birthday. We are free. I am so proud of you. We thought we would have to drop out so many times, but look at us. We did it. Together. I am learning to love and making far too many mistakes yet you keep on forgiving me and loving me back. I intend to spend the rest of my life with you and see the world and do everything and kiss you sideways and kiss you in rhyme. I am sorry I know I promised you shark blankie and sensory survival kit but with China and applications and stuff at home we just haven’t had the fiscal space, but I hope instead this website makes up for it. It is small. But it took me a while. And let’s be all Marxian and say my labor is a commodity so technically I spent a lot of money on this(not that you care, saying this for myself). This is our cabin. This is our place. Whenever you feel anxious, or scared, or alone, or unloved. Come here. Sit in the garden. Go through the drawers and photo albums. Sit with me by the fireplace. Stay. I love you.
      </p>
        <h1 style={{ textAlign: 'center' }}>
            <Link style={{ color: 'black'}}  to="/cabin">go back</Link>
        </h1>

      <div
        className="cursor"
        style={{ left: `${cursorPosition.x}px`, top: `${cursorPosition.y}px` }}
      ></div>
    </div>
  );
};

export default SecretMessage;