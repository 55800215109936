// HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import backyard from './backyard.png';

const Backyard = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#F2EBBF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const columnsStyle = {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '600px',
    height: '600px',
  };

  const textStyle = {
    textDecoration: 'underline',
  };

  return (
    <div className="App" style={appStyle}>
      <div className="columns" style={columnsStyle}>
        <div className="left-column" style={{ flex: 1 }}>
          <figure>
          <img src={backyard} alt="I love you" style={imgStyle} />
          <figcaption><em> Makes a cathedral, him pressing against
me, his lips at my neck, and yes, I do believe
his mouth is heaven, his kisses falling over me like stars. </em></figcaption>
          </figure>
          
        </div>
        <div className="right-column" style={{ flex: 1 }}>
          <h1 style={textStyle}>
            <Link to="/box">dig up secret box</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/outside">back towards cabin</Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Backyard;
