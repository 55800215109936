// ImagePopup.js
import React from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa'; 

const ImagePopup = ({ imageUrl, isOpen, onRequestClose }) => {
  const modalStyle = {
    overlay: {
      backgroundColor: 'black', // Set overlay background to black
      zIndex: 1000, // Ensure the modal is on top of other elements
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', 
      justifyContent: 'center', 
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'transparent',
      border: 'none',
    },
  };

  const imgStyle = {
    maxWidth: '80%', 
    maxHeight: '80vh', 
    objectFit: 'contain',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    fontSize: '1.5rem',
    color: 'white',
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Popup"
      style={modalStyle}
    >
      <FaTimes style={closeButtonStyle} onClick={onRequestClose} />
      <img src={imageUrl} alt="Popup" style={imgStyle} />
    </Modal>
  );
};

export default ImagePopup;
