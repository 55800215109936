// cabin.js
import React from 'react';
import { Link } from 'react-router-dom';
import cabin from './cabin.png';
import AudioPlayer from './AudioPlayer';

const Cabin = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#F2EBBF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const columnsStyle = {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '600px',
    height: '600px',
  };

  const textStyle = {
    textDecoration: 'underline',
  };

  return (
    <div className="App" style={appStyle}>
      <div className="columns" style={columnsStyle}>
        <div className="left-column" style={{ flex: 1 }}>
        <figure>
        <img src={cabin} alt="I love you" style={imgStyle} />
        <figcaption><em>I made
this place for you. A place for to love me.</em></figcaption>
        </figure>
          
          
        </div>
        <div className="right-column" style={{ flex: 1 }}>
          <h1 style={textStyle}>
            <Link to="/message">check sidetable drawer</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/bathroom">to bathroom</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/safe">come closer</Link>
          </h1>
          <h1 style={textStyle}>
            <Link to="/">go back</Link>
          </h1>
          <AudioPlayer />
        </div>
      </div>
    </div>
  );
};

export default Cabin;
