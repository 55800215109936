import React from 'react';
import { Link } from 'react-router-dom';
import warm from './warm.png';

const Warm = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#F2EBBF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const columnsStyle = {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '600px',
    height: '600px',
  };

  const textStyle = {
    textDecoration: 'underline',
  };



  return (
    <div className="App" style={appStyle}>
      <div className="columns" style={columnsStyle}>
        <div className="left-column" style={{ flex: 1 }}>
          <figure>
          <img src={warm} alt="I love you" style={imgStyle} />
          <figcaption><em>You're trying not to tell him you love him, and you're trying to choke down the feeling, and you're trembling, but he reaches over and he touches you, like a prayer for which no words exist, and you feel your heart taking root in your body, like you've discovered something you don't even have a name for. </em></figcaption>
          </figure>
          
        </div>
        <div className="right-column" style={{ flex: 1 }}>
          <h1 style={textStyle}>
            <Link to="/cabin">kiss me</Link>
          </h1>
          <h5>
            (blush while we do what we do under the blankie)
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Warm;
