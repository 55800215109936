import React from 'react';
import love from './comehere.mp3'
const AudioPlayer = () => {
  return (
    <div>
      <audio controls>
        <source src={love} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
