import React from 'react';
import { Link } from 'react-router-dom';
import shower from './shower.png';

const Shower = () => {
  const appStyle = {
    minHeight: '100vh',
    backgroundColor: '#8CBEB2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const columnsStyle = {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
  };

  const imgStyle = {
    width: '600px',
    height: '600px',
  };

  const textStyle = {
    textDecoration: 'underline',
  };

  return (
    <div className="App" style={appStyle}>
      <div className="columns" style={columnsStyle}>
        <div className="left-column" style={{ flex: 1 }}>
          <figure>
          <img src={shower} alt="I love you" style={imgStyle} />
          <figcaption><em>Wearing your clothes or standing in the shower for over an hour, pretending
that this skin is your skin, these hands your hands</em></figcaption>
          </figure>
          
        </div>
        <div className="right-column" style={{ flex: 1 }}>
          <h1 style={textStyle}>
            <Link to="/warm">follow the sound of my voice calling out to you</Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Shower;
